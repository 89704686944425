import axios from "axios";

export class TwitterProfilePicRetriever {

    getProfilePicUrl(username: string): Promise<string> {
        const options = {
            method: 'GET',
            url: 'https://twitter154.p.rapidapi.com/user/details',
            params: {username: username},
            headers: {
                'X-RapidAPI-Key': '71f17edc44msh2f73ac8d1f01d01p1ab07fjsnb6eb05cb7711',
                'X-RapidAPI-Host': 'twitter154.p.rapidapi.com'
            }
        };

        return new Promise<string>((resolve, reject) => {
            axios.request(options).then(function (response) {
                resolve(response.data["profile_pic_url"].replaceAll('_normal', ''));
            }).catch(function (error) {
                reject(error);
            });
        });
    }
}

