import axios from "axios";
import {message} from "antd";

interface MemeResponse {
    resulting_text: string;
}

export type TMemeResponseList = MemeResponse[]

export class MemeGenerator {

    createMeme(url: string): Promise<TMemeResponseList> {
        var formData = new FormData();
        formData.append("url", url);

        let config = {
            url: url
        }

        return axios.post<TMemeResponseList>(
            'https://api-m-7qtp55otdq-uw.a.run.app/generate_from_url?url=' + encodeURI(url)
        ).then( response => response.data )
    }
}