import React, {Ref, useEffect, useLayoutEffect, useRef, useState} from 'react';
import Webcam from "react-webcam";
import {Button, Image, message} from "antd";
import {MemeGenerator} from "../Helpers/MemeGenerator";
import axios from "axios";

const Camera = () => {
    const [fMode, setFMode] = useState<any>("user");
    const size = useWindowSize();
    const [img, setImg] = useState<string | null>(null);

    const generator = new MemeGenerator();

    const isLandscape = size.height <= size.width;
    const ratio = isLandscape ? size.width / size.height : size.height /
        size.width;

    // Hook
    function useWindowSize() {
        const [windowSize, setWindowSize] = useState({
            width: 0,
            height: 0,
        });

        useEffect(() => {
            // Handler to call on window resize
            function handleResize() {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }

            // Add event listener
            window.addEventListener("resize", handleResize);

            // Call handler right away so state gets updated with initial window size
            handleResize();

            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }, []); // Empty array ensures that effect is only run on mount

        return windowSize;
    }

    let videoConstraints = {
        facingMode: fMode,
        width: size.width,
        aspectRatio: ratio
    }

    const webcamRef = React.useRef<Webcam | null>(null);
    const capture = React.useCallback(
        () => {
            if (webcamRef.current) {
                let imgTaken = webcamRef.current.getScreenshot();
                setImg(imgTaken);

                if (imgTaken) {
                    console.log("THERE");

                    fetch(imgTaken)
                        .then(res => res.blob())
                        .then(blob => {
                            const file = new File([blob], "file",{ type: "image/png" });

                            let config = {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }

                            var formData = new FormData();
                            formData.append("file", file);
                            axios.post(
                                'https://api-m-7qtp55otdq-uw.a.run.app/generate_v2',
                                formData,
                                config
                            ).then( response => {
                                console.log(response);
                            }).catch( error => {
                                message.error(error);
                            })
                        })
                }

            }

        },
        [webcamRef]
    );

    return (
        <div className="h-full w-full">
            <Webcam
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className="absolute top-2 left-2 w-[calc(100%-1rem)] h-[calc(100%-1rem)]"
                videoConstraints={videoConstraints}
            />
            <Button
                onClick={() => {
                    if (fMode === "user") {
                        setFMode({ exact: "environment" });
                    } else {
                        setFMode("user");
                    }
                }}
            >
                Flip camera
            </Button>

            <Button
                onClick={() => {
                    capture();
                }}
            >
                Take a shot
            </Button>

            {
                img ? <Image src={img}/> : <div/>
            }
        </div>
    );
};

export default Camera;