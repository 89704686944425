import React, {Dispatch, FunctionComponent, SetStateAction} from 'react';
import {RcFile} from "antd/es/upload";
import {ResponseItem} from "./ButtonsComponent";

interface IProps {
    results: Array<ResponseItem>;
}
const ResultsComponent: FunctionComponent<IProps> = (props) => {
    return (
        <div className="flex flex-col items-start">
            {
                props.results.map( item => {
                    return <div className="bg-slate-700 m-4 rounded-xl" key={item.resulting_text}>
                        <p className="text-white m-4">
                            {item.resulting_text}
                        </p>
                    </div>
                })
            }
            </div>
    );
};

export default ResultsComponent;