import React, {FunctionComponent, useEffect, useState} from 'react';
import {Progress, Spin} from "antd";
import {useMockProgress} from "mock-progress-react";

interface IProps {
    stop: boolean;
}

const FakeProgressComponent: FunctionComponent<IProps> = (props) => {
    const { progress, finish, start } = useMockProgress({timeInterval:380, autoComplete:false});

    useEffect(() => {
        start();
    }, [])

    useEffect(() => {
        if (props.stop) {
            finish();
        }
    }, [props.stop])

    return (
        <div className="flex flex-col items-center font-bold">
            <p>Generating your captions...</p>
            <Progress percent={progress} style={{marginTop: "2rem"}}/>
        </div>
    );
};

export default FakeProgressComponent;