import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { init } from '@amplitude/analytics-browser';

const getUserClientId = () => {
    const storedId = localStorage.getItem("memecaption_client_id")
    if (storedId) {
        return storedId;
    }

    const newId = Math.random().toString(36).substr(2, 18);

    localStorage.setItem("memecaption_client_id", newId);
    return newId
}

init("0e64c6ff03a9d34c73ebc7c04ca99055", getUserClientId());

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

