import React from 'react';

const TermsOfServicePage = () => {
    return (
        <div className="m-8 md:mr-96">
            <h1>Terms & Conditions</h1>
            The terms of service (the "Agreement") govern your use of the MemeCam app (the "App"), including any and all services, features, content, and functionality offered through the App. By accessing or using the App, you agree to be bound by the terms of this Agreement.

            <h3>1. Account Creation and Deletion</h3>
                1.1. To use certain features of the App, you must create an account. You agree to provide accurate and complete information when creating an account, and to update your account information promptly if there are any changes.
            <br/>
                1.2. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify Mobile AI Limited immediately if you suspect any unauthorized use of your account or any other breach of security.
            <br/>
                1.3. You may delete your account at any time by following the instructions provided in the App.

            <h3>2. User Content</h3>
            2.1. You may upload content to the App, including images, videos, and text (collectively, "User Content"). You represent and warrant that you own or have all necessary rights to use and share the User Content and that the User Content does not infringe any third party's intellectual property rights, privacy rights, or other rights.
            <br/>2.2. You grant Mobile AI Limited a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to use, copy, modify, create derivative works based on, distribute, publicly display, publicly perform, and otherwise exploit in any manner your User Content in all formats and distribution channels now known or hereafter devised (including in connection with the App and Mobile AI Limited's business and on third-party sites and services), without further notice to or consent from you, and without the requirement of payment to you or any other person or entity.
            <br/>2.3. Mobile AI Limited reserves the right to remove any User Content from the App at any time and for any reason.

            <h3>3. In-App Purchases and Subscriptions</h3>
            3.1. The App may offer in-app purchases and subscriptions. You agree to pay all fees and charges associated with in-app purchases and subscriptions, and you authorize Mobile AI Limited to charge your chosen payment method for any such fees and charges.
            <br/>3.2. All fees and charges are non-refundable, except as required by applicable law.

            <h3>4. Feedback and Suggestions</h3>
            4.1. Mobile AI Limited welcomes feedback and suggestions from users of the App. By providing feedback or suggestions, you grant Mobile AI Limited a perpetual, irrevocable, worldwide, royalty-free, and non-exclusive license to use and incorporate any feedback or suggestions in Mobile AI Limited's products and services without further notice to or consent from you, and without the requirement of payment to you or any other person or entity.

            <h3>5. Contact Information</h3>
            5.1. If you have any questions about the App or this Agreement, you may contact Mobile AI Limited at admin@mobile-ai.org.

                <h3>6. Governing Law and Dispute Resolution</h3>
                6.1. This Agreement and any dispute arising out of or relating to this Agreement or the App shall be governed by and construed in accordance with the laws of the jurisdiction where Mobile AI Limited is located, without giving effect to any principles of conflicts of law.
                <br/>6.2. Any dispute arising out of or relating to this Agreement or the App shall be resolved through binding arbitration in accordance with the rules of the arbitration association located in the jurisdiction where Mobile AI Limited is located, and judgment upon the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. The prevailing party in any such arbitration or proceedings shall be entitled to recover its reasonable attorneys' fees and costs incurred in connection therewith.

                <h3>7. Disclaimer of Warranties</h3>
                7.1. THE APP AND ALL CONTENT AND SERVICES PROVIDED THROUGH THE APP ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY FOR INFORMATION, SERVICES, UNINTERRUPTED ACCESS, OR PRODUCTS PROVIDED THROUGH OR IN CONNECTION WITH THE APP, INCLUDING WITHOUT LIMITATION THE SOFTWARE LICENSED TO YOU AND THE RESULTS OBTAINED THROUGH THE APP. SPECIFICALLY, MOBILE AI LIMITED DISCLAIMS ANY AND ALL WARRANTIES, INCLUDING WITHOUT LIMITATION: 1) ANY WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY, USEFULNESS, OR CONTENT OF INFORMATION, PRODUCTS OR SERVICES, AND 2) ANY WARRANTIES OF TITLE, WARRANTY OF NON-INFRINGEMENT, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.

                <h3>8. Limitation of Liability</h3>
                8.1. IN NO EVENT SHALL MOBILE AI LIMITED OR ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE APP OR THIS AGREEMENT, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOST PROFITS OR REVENUES, LOSS OF DATA, BUSINESS INTERRUPTION, OR COSTS OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, WHETHER OR NOT MOBILE AI LIMITED HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL MOBILE AI LIMITED'S AGGREGATE LIABILITY FOR ANY CLAIM ARISING OUT OF OR RELATED TO THE APP OR THIS AGREEMENT EXCEED THE GREATER OF ONE HUNDRED DOLLARS ($100) OR THE AMOUNTS PAID BY YOU TO MOBILE AI LIMITED FOR USE OF THE APP DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM.

                <h3>9. Miscellaneous</h3>
                9.1. This Agreement constitutes the entire agreement between you and Mobile AI Limited with respect to the App and supersedes all prior or contemporaneous communications and proposals, whether oral or written, between you and Mobile AI Limited.
                <br/>9.2. If any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect and enforceable.
                <br/>9.3. Mobile AI Limited's failure to enforce any right or provision of this Agreement will not be deemed a waiver of such right or provision.
                <br/>9.4. Mobile AI Limited may assign this Agreement, in whole or in part, at any time with or without notice to you. You may not assign this Agreement or transfer any rights to use the App.
                <br/>9.5. The section titles in this Agreement are for convenience only and have no legal or contractual effect.
                <br/>9.6. You agree that any notices or other communications provided by Mobile AI Limited electronically satisfy any legal requirement that such communications be in writing.
                <br/>9.7. You agree that Mobile AI Limited may send you text messages or push notifications through the App for informational or promotional purposes.
                <br/>9.8. You acknowledge and agree that Mobile AI Limited may update this Agreement from time to time, and your continued use of the App after any such update constitutes your acceptance of the updated Agreement.
        </div>
    );
};

export default TermsOfServicePage;