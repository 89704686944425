import React, {Dispatch, FunctionComponent, SetStateAction} from 'react';
import {Button, Image, message} from "antd";
import axios from "axios";
import { RcFile } from 'antd/es/upload';

export interface ResponseItem {
    resulting_text: string
}

interface IProps {
    token: string | undefined;
    setImageUrl: Dispatch<SetStateAction<string | undefined>>;
    setResults: Dispatch<SetStateAction<Array<ResponseItem>>>;
    setUploading: Dispatch<SetStateAction<boolean>>;
    imageData: RcFile | undefined;
    setImageData: Dispatch<SetStateAction<RcFile | undefined>>;
    setStop: Dispatch<SetStateAction<boolean>>;
}

const ButtonsComponent: FunctionComponent<IProps> = (props: IProps) => {

    function timeout(delay: number) {
        return new Promise( res => setTimeout(res, delay) );
    }

    const finishUpload = async (response: any) => {
        props.setStop(true);

        await timeout(500);
        props.setStop(false);
        props.setResults(response.data);
        props.setUploading(false);
    }

    return (
        <div className="flex flex-col items-center gap-4">
            <Button
                type="primary"
                onClick={() => {
                    props.setResults([]);

                    let config = {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${props.token ? props.token : "null"}`
                        }
                    }

                    var formData = new FormData();
                    if (props.imageData) {
                        formData.append("file", props.imageData);
                    }
                    props.setUploading(true);
                    axios.post(
                        props.token ? 'https://api-m-7qtp55otdq-uw.a.run.app/generate_v1' : 'https://api-m-7qtp55otdq-uw.a.run.app/generate_v2',
                        formData,
                        config
                    ).then( response => {
                        finishUpload(response);
                    }).catch( error => {
                        message.error(error);
                        props.setUploading(false);
                    })
                }}
            >
                Generate again
            </Button>
            <Button
                type="link"
                onClick={() => {
                    props.setResults([]);
                    props.setImageData(undefined);
                    props.setImageUrl(undefined);
                }}
            >
                Reset
            </Button>
        </div>
    );
};

export default ButtonsComponent;