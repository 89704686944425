import React, {Dispatch, FunctionComponent, SetStateAction} from 'react';
import {InboxOutlined} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import {RcFile} from "antd/es/upload";
import {ResponseItem} from "./ButtonsComponent";
import {UploadProps} from "antd";

interface IProps {
    uploading: boolean;
    uploadProps: UploadProps;
}

const UploadingComponent: FunctionComponent<IProps> = (props) => {
    return (
        <div className="flex flex-col m-4">
            <p className="text-center font-bold">
                Source photo to create meme caption
            </p>
            <Dragger {...props.uploadProps} disabled={props.uploading}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Dragger>
            <p>
                Requirements for Photos:
                <ul>
                    <li>
                        Images should be free of blurs or distortion.
                    </li>
                    <li>
                        Images must not contain over or underexposed areas.
                    </li>
                    <li>
                        Image size should not exceed 10MB.
                    </li>
                    <li>
                        Images should not contain NSFW.
                    </li>
                </ul>
            </p>
        </div>
    );
};

export default UploadingComponent;