import { Modal } from 'antd';
import React, {Dispatch, FunctionComponent, SetStateAction} from 'react';

interface IProps {
    isModalOpen: boolean;
    setPrivacyOpen: Dispatch<SetStateAction<boolean>>;
}

const PrivacyModal: FunctionComponent<IProps> = (props: IProps) => {
    return (
        <Modal
            title="MemeCam Privacy Policy"
            onOk={() => props.setPrivacyOpen(false)}
            onCancel={() => props.setPrivacyOpen(false)}
            open={props.isModalOpen}
            width={768}
        >
            <div>
                MemeCam (“we”, “us” or “our”) knows that you care about how your personal information is used and shared and we take the protection of your data very seriously. This Privacy Policy explains our practices regarding the collection, use and disclosure of information gathered through our service provided via memecam.app, memecam.ai, memit.app (the “Service”).
                <br/>
                <br/>
                We use Firebase as a platform for hosting and managing the Service. Firebase uses cookies and other technologies in order to deliver, monitor and improve our Service, as well as for advertising purposes. Please refer to Firebase's Privacy Policy for more information.
                <br/>
                <br/>
                By using the Service, you agree to the terms of this Privacy Policy. If you do not agree with this Privacy Policy, please do not access or use the Service.
                <br/>
                <br/>

                <h1>Information We Collect</h1>

                We may collect personal identifiable information from Users when they register on our Service, interact with us via social media or contact us directly for support. In particular:
                <br/>

                <ul>
                    <li>Email address – obtained when signing up for an account;</li>

                    <li>Profile information such as name, username, password;</li>

                    <li>Geographic location (city level);</li>

                    <li>IP Address;</li>
                </ul>


                We may also collect anonymous demographic data, which is not unique to any individual user but provides us useful insights into usage patterns (e.g., device type).
                <br/>
                <br/>

                <h1>Use Of Your Information</h1>

                We use the collected information we have regarding Users in order to provide our Service and excellent customer service. By using our Services, you understand and agree that we may contact you via email or other methods including push notifications on certain devices with important updates related to your use of the Service including but not limited to security alerts or changes in Terms & Conditions or Privacy Policies. Additionally, we may use User data for research purposes in order to improve future versions of the MemeCam application. Our research does not identify any personal details relating to individual users unless explicitly stated at time of collection and will be reported only in aggregate statistical form.
                <br/>
                <br/>

                <h1>Disclosure Of Your Information</h1>

                We share your personal identifiable information with third party vendors who assist us in providing services related directly to the operation of MemeCam such as payment processing companies who facilitate subscription payments for premium features within MemeCam application (ex: Stripe). These third parties are bound by contracts with us such that all user data must remain confidential and secure according to applicable laws and regulations. Additionally, it is important that you know that we may disclose non-personally identifiable information publicly or with interested parties – however no one will be able to identify any individual users from such disclosures unless expressly specified otherwise at time of collection/disclosure.


                <h1>Security Of Your Information</h1>

                At MemeCam we take data security seriously because protecting your data is integral towards providing a reliable service built on trustworthiness between us & our user base – therefore it is hugely important that only authorized personnel can access user data while all unauthorized persons/organizations are denied access strictly enforced by technical measures taken across many layers from physical device penetration tests & involving multi-factor authentication services (MFA). Additionally all sensitive & confidential user communications are encrypted using industry standard SSL encryption techniques whenever possible so no unauthorized person can obtain such communication even if intercepted during transmission over open networks like Wi-Fi hotspots without proper security protocols enabled/in place around such networks first - thereby ensuring maximum possible safety against intrusion attempts & malicious snoops alike!
            </div>
        </Modal>
    );
};

export default PrivacyModal;






