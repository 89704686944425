import { Button } from 'antd';
import React from 'react';

const AppStoreBanner = () => {
    return (
        <div id="banner" tabIndex={-1}
             className="flex justify-between w-full bg-gray-50 border border-b border-gray-200 dark:border-gray-700 dark:bg-gray-800">
            <div className="flex flex-col items-center mx-auto">
                <p className="text-md font-medium text-gray-900 dark:text-white">
                    <span
                        className="inline-flex bg-primary-100 text-primary-800 text-md font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                        New
                    </span>
                    We have launched MemeCam app for iOS!
                </p>
                <Button
                    style={{marginBottom: "1rem"}}
                    type="primary"
                    onClick={() => window.open("https://apps.apple.com/app/apple-store/id6446517243?pt=120998471&ct=Installs%20from%20our%20site&mt=8")}
                >
                    Check it out!
                </Button>
            </div>
        </div>
    );
};

export default AppStoreBanner;