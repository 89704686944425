import React, {useEffect, useRef, useState} from 'react';
import CurrentUserComponent from "./UserComponent/CurrentUserComponent";
import WorkspaceComponent from "./Workspace/WorkspaceComponent";
import PrivacyModal from "./PrivacyModal";
import {Button, Image} from "antd";
import MainFooter from "./MainFooter";

import Indians from "../Resources/1.jpg";
import Results from "../Resources/results.png";
import AppStoreBanner from "./AppStoreBanner";
import MobileDetect from 'mobile-detect';

const MainNoAuth = () => {
    const [isIphone, setIsIphone] = useState(false);
    const [isPrivacyOpen, setPrivacyOpen] = useState(false);

    useEffect(() => {
        const mobileDetect = new MobileDetect(window.navigator.userAgent);
        setIsIphone(mobileDetect.os() === "iOS");
        console.log(mobileDetect.os());
    }, []);

    return (
        <div className="flex flex-col sm:justify-center h-full min-h-screen w-screen bg-slate-200 mt-24 sm:mt-4">
            <div className="flex flex-col items-center">
                <div className="w-full absolute top-0">
                    <AppStoreBanner/>
                </div>
            </div>

            <h1 className="mx-8 my-8 text-center">
                Use MemeCam AI to create super funny memes with your photos in a few seconds.
            </h1>

            <h3 className="mx-16 text-center">
                Spread the meme love responsibly with MemeCam!
                <br/>

            </h3>
            <p className="mx-16 text-center">
                Our AI-powered mobile app creates hilarious memes in seconds, without storing your images - guaranteeing a safe and secure experience for all meme enthusiasts.
                <br/>
                Share wisely and have fun!
            </p>

            <div>
                <WorkspaceComponent token={undefined}/>
            </div>
            <div className="flex justify-center width-8">
                <Button
                    type="link"
                    onClick={() => setPrivacyOpen(true)}
                >
                    Privacy policy
                </Button>
            </div>
            <PrivacyModal isModalOpen={isPrivacyOpen} setPrivacyOpen={setPrivacyOpen}/>
        </div>

    );
};

export default MainNoAuth;