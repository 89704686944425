import React, {useEffect, useState} from 'react';
import MobileDetect from "mobile-detect";
import AppStoreBanner from "./AppStoreBanner";
import WorkspaceComponent from "./Workspace/WorkspaceComponent";
import {Button, Carousel, Image, Input, Spin} from "antd";
import PrivacyModal from "./PrivacyModal";
import {TwitterProfilePicRetriever} from "../Helpers/TwitterProfilePicRetriever";
import {MemeGenerator} from "../Helpers/MemeGenerator";

const TwitterPicIntoMeme = () => {
    const [isPrivacyOpen, setPrivacyOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [currentUrl, setCurrentUrl] = useState<string | undefined>(undefined);
    const [captions, setCaptions] = useState<string[] | undefined>(undefined);

    const picHelper = new TwitterProfilePicRetriever();
    const generator = new MemeGenerator();

    const onGenerateClick = () => {
        setIsLoading(true);
        setCaptions(undefined);
        setCurrentUrl(undefined);

        const clearedUsername = username.replaceAll('@', '');

        picHelper.getProfilePicUrl(clearedUsername).then( url => {
            setCurrentUrl(url);
            return generator.createMeme(url);
        }).then( results => {
            console.log(results);
            const rawStrings = results.map(item => item.resulting_text);
            setCaptions(rawStrings);
        }).catch( error => {
            alert(error);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    return (
        <div className="flex flex-col sm:justify-center h-full min-h-screen w-screen bg-slate-200 gap-8 mt-24">
            <div className="w-full absolute top-0">
                <AppStoreBanner/>
            </div>

            {
                isLoading ? <Spin className="mt-8"/> : <div className="flex flex-col gap-4 mx-16">
                    <p className="text-center mt-8">
                        Type in your twitter username and tap "Generate" to create memes with your profile picture
                    </p>

                    <Input
                        placeholder="Your username with or without @"
                        onChange={ e => {
                            setUsername(e.target.value);
                        }}
                    />

                    <Button
                        style={{marginTop: "1rem"}}
                        type="primary"
                        onClick={onGenerateClick}
                        disabled={username.length < 1}
                    >
                        Generate
                    </Button>
                </div>
            }

            {
                captions ?
                    <div className="flex flex-wrap gap-8 grow-0 justify-center">
                        {
                            captions.map( caption => {
                                return <div className="flex flex-col items-center gap-2 bg-white rounded-lg shadow-lg w-72">
                                    <div className="relative">
                                        <img src={currentUrl} className="mt-4 w-64 rounded-lg"/>
                                        <p className="text-xl uppercase text-center absolute mx-3 left-2 right-2 bottom-0.5 text-white drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]" style={{fontFamily: "Impact"}}>{caption}</p>
                                    </div>
                                    <Button
                                        type="link"
                                        className="mb-2"
                                        onClick={() => window.open("https://apps.apple.com/app/apple-store/id6446517243")}
                                    >
                                        Download
                                    </Button>
                                </div>
                            })
                        } </div>
                    : <div/>
            }

            <div className="flex justify-center width-8">
                <Button
                    type="link"
                    onClick={() => setPrivacyOpen(true)}
                >
                    Privacy policy
                </Button>
            </div>
            <PrivacyModal isModalOpen={isPrivacyOpen} setPrivacyOpen={setPrivacyOpen}/>
        </div>

    );
};

export default TwitterPicIntoMeme;