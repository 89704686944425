import React, {useState} from 'react';
import './App.css';
import MainWithAuth from "./Components/MainWithAuth";
import MainNoAuth from "./Components/MainNoAuth";
import MainFooter from "./Components/MainFooter";
import PrivacyPolicyPage from "./Components/PrivacyPolicyPage";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TermsOfServicePage from "./Components/TermsOfServicePage";
import { ConfigProvider } from 'antd';
import TwitterPicIntoMeme from "./Components/TwitterPicIntoMeme";
import Camera from "./Components/Camera";

function App() {

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#7C56F5',
                },
            }}
        >
            <Router>
                <Routes>
                    <Route key="workspace" path="/" element={<MainNoAuth/>}/>
                    <Route key="privacy" path="/privacy-policy" element={<PrivacyPolicyPage/>}/>
                    <Route key="terms" path="/terms" element={<TermsOfServicePage/>}/>
                    <Route key="twitter" path="/twitter" element={<TwitterPicIntoMeme/>}/>
                    <Route key="webcam" path="/webcam" element={<Camera/>}/>
                </Routes>
            </Router>
        </ConfigProvider>
    );
}

export default App;
