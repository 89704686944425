import React, {Dispatch, FunctionComponent, SetStateAction, useRef, useState} from 'react';
import { InboxOutlined } from '@ant-design/icons';
import type {UploadFile, UploadProps} from 'antd';
import {Button, Image, message, Progress, Spin, Upload} from 'antd';
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase";
import {RcFile, UploadChangeParam } from 'antd/es/upload';
import axios from "axios";
import ButtonsComponent, {ResponseItem} from "./ButtonsComponent";
import UploadingComponent from "./UploadingComponent";
import ResultsComponent from "./ResultsComponent";
import { track } from '@amplitude/analytics-browser';
import FakeProgressComponent from "../FakeProgressComponent";
import {Widget} from "@typeform/embed-react";
import ExplanationComponent from "../ExplanationComponent";

const { Dragger } = Upload;

interface IProps {
    token: string | undefined;
}

const WorkspaceComponent: FunctionComponent<IProps> = (props: IProps) => {
    const [uploading, setUploading] = useState(false);
    const [results, setResults] = useState(Array<ResponseItem>);
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
    const [progress, setProgress] = useState(0);
    const [imageData, setImageData] = useState<RcFile | undefined>(undefined);
    const [stop, setStop] = useState(false);

    const workRef = useRef<any>(null);

    const [
        user,
        userLoading,
        userError
    ] = useAuthState(auth);

    const finishDownload = async (info: UploadChangeParam<UploadFile<any>>) => {
        function timeout(delay: number) {
            return new Promise( res => setTimeout(res, delay) );
        }

        setStop(true);

        await timeout(500);
        setUploading(false);
        setStop(false);
        const reader = new FileReader();
        reader.addEventListener('load', () => setImageUrl(reader.result as string));
        reader.readAsDataURL(info.file.originFileObj as Blob);
        setImageData(info.file.originFileObj);
        setResults(info.file.response);
    }

    const uploadProps: UploadProps = {
        name: 'file',
        withCredentials: true,
        multiple: false,
        action: props.token ? 'https://api-m-7qtp55otdq-uw.a.run.app/generate_v1' : 'https://api-m-7qtp55otdq-uw.a.run.app/generate_v2',
        headers: props.token ? { authorization: "Bearer " + props.token } : {},
        showUploadList: false,
        method: 'POST',
        beforeUpload: (file) => {
            track("UPLOAD_TAP");
            // @ts-ignore
            window.gtag(
                'event',
                'conversion', {
                    'send_to': 'AW-11044127572/KMn5CNnq9JMYENSGoJIp'
                });
            if (props.token && !user) {
                message.error("Please Sign In to start using MemeCam");
                return false;
            }
            const isPNG = file.type === 'image/png';
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg';
            const isOk = isPNG || isJPG;
            if (!isOk) {
                message.error(`${file.name} is not an image`);
            } else {
                setUploading(true);
            }

            return isOk;
        },
        onChange(info) {
            const { status } = info.file;
            setProgress(info.event?.percent ?? 0)
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                finishDownload(info);
            } else if (status === 'error') {
                setUploading(false);
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        }
    };

    return (
        <div className="w-full bg-slate-200 flex flex-col items-center">
            {
                uploading
                    ? <FakeProgressComponent stop={stop}/>
                    : <div/>
            }
            {
                imageUrl
                    ? <div className="my-8"><Image src={imageUrl} width={256} className="rounded-lg"/></div>
                    : <div ref={workRef}><UploadingComponent uploading={uploading} uploadProps={uploadProps}/></div>
            }
            {
                results.length > 0
                    ? <ButtonsComponent
                        imageData={imageData} setImageData={setImageData}
                        setResults={setResults} setUploading={setUploading} token={props.token}
                        setImageUrl={setImageUrl} setStop={setStop}
                    />
                    : <div/>
            }
            <ResultsComponent results={results}/>
            {
                results.length > 0
                    ? <Widget id="AnL0hVDH" className="w-full my-4" style={{height: "24rem"}} chat={true}/>
                    : <div/>
            }
        </div>
    );
};

export default WorkspaceComponent;